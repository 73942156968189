import React from 'react'
import { Container, Row, Col } from '../../../../components/ui/wrapper'
import Timeline from '../../../../components/ui/timeline-ru'
import ImageOne from '../../../../data/images/bg/machines/smt/reflow/reflow-oven.png'
import ImageTwo from '../../../../data/images/bg/machines/smt/reflow/mid-reflow.png'
import ImageThree from '../../../../data/images/bg/machines/smt/reflow/batch-reflow.png'
import { SectionWrap } from './style'


const TimelineArea = (props) => {
    const content = [
        {
            year: "SMART ПЕЧИ",
            image: ImageOne,
            title: "Серийное производство",
            desc: "Оборудования для монтажа электронных компонентов SMD на поверхность печатных плат (SMT) конвейерного типа для линии. Оборудование предназначено для оплавления паяльной пасты на контактных площадках печатных плат. Применяется в условиях серийного и крупносерийного производства монтажа печатных плат для технологии поверхностного монтажа SMT как оборудование работающее в составе линии SMT. Может применятся в условиях мелкосерийного монтажа печатных плат, как отдельностоящее оборудование, для пайки изделий с повышенными требованиями в эксплуатации. Повышает производительности и качество технологических процессов монтажа SMD на печатные платы для технологии поверхностного монтажа (SMT).",
            path: "/оборудование-для-монтажа/поверхностный-монтаж/печи-паяльные/конвекционная-пайка",
            lang: "Подробно..."
        },
        {
            year: "КОНВЕЙЕРНЫЕ",
            image: ImageTwo,
            title: "Печи паяльные конвейерные среднего уровня",
            desc: "Оборудования для монтажа электронных компонентов SMD на поверхность печатных плат SMT – конвейерного типа. Оборудование предназначено для оплавления паяльной пасты на контактных площадках печатных плат с минимальным термоударом для компонентов. Применяется в условиях мелкосерийного и серийного производства монтажа печатных плат для работы в линии и как отдельностоящее оборудование. Повышает производительности и качество технологических процессов монтажа SMD на печатные платы.",
            path: "/оборудование-для-монтажа/поверхностный-монтаж/печи-паяльные/конвекционные-печи-оплавления",
            lang: "Подробно..."
        },
        {
            year: "НАСТОЛЬНЫЕ",
            image: ImageThree,
            title: "Паяльные печи начального уровня",
            desc: "Оборудования для монтажа электронных компонентов SMD на поверхность печатных плат SMT камерного и конвейерного типов. Оборудование предназначено для оплавления паяльной пасты на контактных площадках печатных плат. Применяется в условиях мелкосерийного производства монтажа печатных плат  и производства прототипов в лабораторных или домашних условиях. Повышает производительности и качество технологических процессов монтажа SMD на печатные платы для технологии поверхностного монтажа (SMT).",
            path: "/оборудование-для-монтажа/поверхностный-монтаж/печи-паяльные/печи-для-пайки-smd",
            lang: "Подробно..."
        }
    ]
    const contentua = [
        {
            year: "SMART ПЕЧІ",
            image: ImageOne,
            title: "Серійне виробництво",
            desc: "Обладнання для монтажу електронних компонентів SMD на поверхню друкованих плат (SMT) конвеєрного типу для лінії. Обладнання призначене для оплавлення паяльної пасти на контактних площадках друкованих плат. Застосовується в умовах серійного і  багато серійного виробництва монтажу друкованих плат для технології поверхневого монтажу SMT, як обладнання працює в складі лінії SMT. Може застосовуватися в умовах дрібносерійного монтажу друкованих плат, як окреме обладнання, для паяння виробів з підвищеними вимогами в експлуатації. Підвищує продуктивності і якість технологічних процесів монтажу SMD на друковані плати для технології поверхневого монтажу (SMT).",
            path: "/ua/обладнання-для-монтажу/поверхневий-монтаж/печі-паяльні/конвекційна-пайка",
            lang: "Докладно..."
        },
        {
            year: "КОНВЕЄРНІ",
            image: ImageTwo,
            title: "Печі паяльні конвеєрні середнього рівня",
            desc: "Обладнання для монтажу електронних компонентів SMD на поверхню друкованих плат SMT - конвеєрного типу. Обладнання призначене для оплавлення паяльної пасти на контактних площадках друкованих плат з мінімальним температурним навантаженням для компонентів. Застосовується в умовах дрібносерійного і серійного виробництва монтажу друкованих плат для роботи в лінії і як окреме обладнання. Підвищує продуктивності і якість технологічних процесів монтажу SMD на друковані плати.",
            path: "/ua/обладнання-для-монтажу/поверхневий-монтаж/печі-паяльні/конвекційні-печі-оплавлення",
            lang: "Докладно..."
        },
        {
            year: "НАСТІЛЬНІ",
            image: ImageThree,
            title: "Паяльні печі початкового рівня",
            desc: "Обладнання для монтажу електронних компонентів SMD на поверхню друкованих плат SMT камерного і конвеєрного типів. Устаткування призначене для оплавлення паяльної пасти на контактних площадках друкованих плат. Застосовується в умовах дрібносерійного виробництва монтажу друкованих плат і виробництва прототипів в лабораторних або домашніх умовах. Підвищує продуктивності і якість технологічних процесів монтажу SMD на друковані плати для технології поверхневого монтажу (SMT).",
            path: "/ua/обладнання-для-монтажу/поверхневий-монтаж/печі-паяльні/печі-для-пайки-smd",
            lang: "Докладно..."
        }
    ]
    if (props.lang === "ru") {
        return (
            <SectionWrap>
                <Container>
                    <Row>
                    <Col lg={12}>
                           <h6 style={{textAlign: "justify"}}>Печи паяльные предназначены для оплавления паяльной пасты на контактных площадках печатных плат при поверхностном монтаже (SMT) электронных компонентов (SMD) на платы. Применят паяльные печи и для полимеризации клея при фиксации компонентов SMD для пайки волной припоя при смешанном монтаже (монтаж на поверхность + монтаж в отверстия). Оборудование используется для работы в составе производственной линии и как отдельно стоящее оборудование.</h6>
                        </Col>
                        <Col lg={12}>
                            <Timeline items={content} />
                        </Col>
                    </Row>
                </Container>
            </SectionWrap>
        )
    } else if (props.lang === "uk") {
        return (
            <SectionWrap>
                <Container>
                    <Row>
                    <Col lg={12}>
                           <h6 style={{textAlign: "justify"}}>Обладнання для поверхневого монтажу для встановлення електронних компонентів SMD на друковані плати. Ручні і напів-автоматичні маніпулятори для встановлення компонентів застосовуються в умовах дрібносерійного виробництва, у виробництві прототипів, для лабораторій і навчальних закладів. Автомати встановлення компонентів початкового рівня застосовують в умовах дрібносерійного виробництва, для автоматизації процесів поверхневого монтажу SMT. Автомати встановлення компонентів для роботи в лінії, застосовують в умовах серійного і багато серійного виробництва.</h6>
                        </Col>
                        <Col lg={12}>
                            <Timeline items={contentua} />
                        </Col>
                    </Row>
                </Container>
            </SectionWrap>
        )
    }
}

export default TimelineArea
